import { useState, useRef, useEffect } from 'react';
import { TonConnectButton } from '@tonconnect/ui-react';
import { motion } from 'framer-motion';
import demo from '../../assets/icons/jellybet-currency-icon.png';
import ton from '../../assets/icons/ton_symbol.png';
import './Header.css';
import {
  SoundIcon,
  SoundOffIcon,
  MusicIcon,
  MusicOffIcon,
  BurgerBtnIcon,
  CloseIcon,
} from '../../assets/icons/icons';
import {
  DEMO_CURRENCY,
  INIT_DEMO_BALANCE,
  TON_CURRENCY,
  DEMO_ROUNDED_DECIMALS,
  TON_ROUNDED_DECIMALS,
} from '../../assets/utils/constants';
import useSounds from '../../assets/hooks/useSounds';
import useTgMiniApp from '../../assets/hooks/useTgMiniApp';
import useAnimatedNumber from '../../assets/hooks/useAnimatedNumber';

function Header({
  demoBalance,
  setDemoBalance,
  isDemo,
  handleSwitchDemo,
  isGamePlaying,
  isBlocked,
  balance,
}) {
  const { tgMiniApp } = useTgMiniApp();
  const { isSoundOn, isMusicOn, toggleSound, toggleMusic } = useSounds();
  const popover = useRef();
  const [isPopoverClosed, setIsPopoverClosed] = useState(false);
  const balanceValue = isDemo ? demoBalance : balance;
  const { rounded } = useAnimatedNumber(
    balanceValue,
    isDemo ? DEMO_ROUNDED_DECIMALS : TON_ROUNDED_DECIMALS
  );

  useEffect(() => {
    if (!popover?.current) return;

    const popoverElement = popover.current;
    function checkVisibility() {
      setIsPopoverClosed(popoverElement.checkVisibility());
    }

    popoverElement.addEventListener('toggle', checkVisibility);
    return () => popoverElement.removeEventListener('toggle', checkVisibility);
  }, [popover]);

  return (
    <header className="header">
      <div className="header__container">
        <div className="header__info-block">
          <div
            className={`header__demo-block ${isGamePlaying || isBlocked ? 'header__demo-block_disabled' : ''
              }`}
            onClick={
              isDemo ? () => setDemoBalance(INIT_DEMO_BALANCE) : undefined
            }
          >
            <img
              className="header__currency-icon"
              src={isDemo ? demo : ton}
              alt=""
            />
            <p className="header__demo-text">
              <motion.span className="header__demo-number">
                {rounded}
              </motion.span>{' '}
              {isDemo ? DEMO_CURRENCY : TON_CURRENCY}
            </p>
          </div>

          <div
            className={`header__wallet-block ${isGamePlaying || isBlocked ? 'header__wallet-block_disabled' : ''
              }`}
          >
            <TonConnectButton />
          </div>

          <button
            className="header__burger-button"
            type="button"
            aria-label="Open popover"
            popovertarget="controls-popover"
            popovertargetaction="toggle"
          >
            {!isPopoverClosed ? (
              <BurgerBtnIcon
                mainClassName="header__burger-icon"
                fillClassName="header__burger-icon-fill"
              />
            ) : (
              <CloseIcon
                mainClassName="header__close-icon"
                fillClassName="header__close-icon-fill"
              />
            )}
          </button>
        </div>

        <div
          className="header__popover"
          popover="auto"
          id="controls-popover"
          ref={popover}
        >
          <div className="header__controls-block">
            <div
              className={`header__demo-switch-container ${isGamePlaying || isBlocked
                ? 'header__demo-switch-container_disabled'
                : ''
                } ${!isDemo ? 'header__demo-switch-container_inactive' : ''}`}
            >
              <div
                className={`header__demo-switch ${isDemo ? 'header__demo-switch_active' : ''
                  }`}
                onClick={handleSwitchDemo}
              >
                <div className="header__demo-switch-circle" />
              </div>
              <p className="header__demo-switch-text">Demo mode</p>
            </div>

            <div className="header__sound-block">
              <button
                className="header__sound-button"
                type="button"
                onClick={toggleSound}
              >
                {isSoundOn ? (
                  <SoundIcon
                    mainClassName="header__sound-icon"
                    fillClassName="header__sound-icon-fill"
                  />
                ) : (
                  <SoundOffIcon
                    mainClassName="header__sound-icon"
                    fillClassName="header__sound-iconFill"
                  />
                )}
              </button>
              <button
                className="header__sound-button"
                type="button"
                onClick={toggleMusic}
              >
                {isMusicOn ? (
                  <MusicIcon
                    mainClassName="header__music-icon"
                    fillClassName="header__music-icon-fill"
                  />
                ) : (
                  <MusicOffIcon
                    mainClassName="header__music-icon"
                    fillClassName="header__music-iconFill"
                  />
                )}
              </button>
            </div>

            <a
              className="header__close-app-button"
              type="button"
              href='https://t.me/megadicee_support'
              target='_blank'
              rel='noreferrer'
            >
              Support
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
