import { useRef, useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { ArrowBtnIcon } from '../../../assets/icons/icons';
import './DiceBet.css';
import { START_BET_MEGA_DICE } from '../../../assets/utils/constants';

function DiceBet({
  up,
  position,
  handleReduceBet,
  handlePositionChange,
  handleIncreaseBet,
  toggleRange,
}) {
  const slider = useRef(null);
  const [sliderWidth, setSliderWidth] = useState(0);

  // format position label
  function valueLabelFormat(value) {
    return value.toFixed(2);
  }

  useEffect(() => {
    if (!slider?.current) return;

    const width = slider.current.offsetWidth;
    setSliderWidth(width);
  }, [slider]);

  return (
    <div className="dice-bet">
      <p className="dice-bet__title">Guess where dice will fall</p>

      <div className="dice-bet__slider-block">
        <button
          className="dice-bet__amount-btn"
          type="button"
          aria-label="Reduce the position"
          onClick={handleReduceBet}
        >
          <ArrowBtnIcon
            mainClassName="dice-bet__amount-btn-icon"
            fillClassName="dice-bet__amount-btn-icon-fill"
          />
        </button>

        <div className="dice-bet__slider-container">
          <div className="dice-bet__slider-line-container" ref={slider}>
            <div className="dice-bet__slider-line">
              {Array(Math.ceil(sliderWidth / 4))
                .fill(' ')
                .map((_, i) => (
                  <div className="dice-bet__slider-item" key={`slider${i}`} />
                ))}
            </div>
            <div
              className={`dice-bet__slider-line dice-bet__slider-line_type_active ${
                !up ? 'dice-bet__slider-line_visible' : ''
              }`}
              style={{ width: position + '%' }}
            >
              {Array(Math.floor((sliderWidth * (position / 100)) / 4))
                .fill(' ')
                .map((_, i) => (
                  <div
                    className="dice-bet__slider-item dice-bet__slider-item_type_active"
                    key={`slider${i}`}
                  />
                ))}
            </div>
            <div
              className={`dice-bet__slider-line dice-bet__slider-line_type_active dice-bet__slider-line_reverse ${
                up ? 'dice-bet__slider-line_visible' : ''
              }`}
              style={{ width: `${100 - position}%` }}
            >
              {Array(Math.floor((sliderWidth * ((100 - position) / 100)) / 4))
                .fill(' ')
                .map((_, i) => (
                  <div
                    className="dice-bet__slider-item dice-bet__slider-item_type_active"
                    key={`slider${i}`}
                  />
                ))}
            </div>
          </div>
          <Slider
            aria-label="position"
            min={0}
            max={100}
            step={0.15}
            valueLabelDisplay="on"
            valueLabelFormat={valueLabelFormat}
            defaultValue={START_BET_MEGA_DICE}
            value={position}
            onChange={handlePositionChange}
          />
          <p className="dice-bet__slider-text">0</p>
          <p className="dice-bet__slider-text dice-bet__slider-text_type_max">
            100
          </p>
        </div>
        <button
          className="dice-bet__amount-btn dice-bet__amount-btn_type_increase"
          type="button"
          aria-label="Increase the position"
          onClick={handleIncreaseBet}
        >
          <ArrowBtnIcon
            mainClassName="dice-bet__amount-btn-icon"
            fillClassName="dice-bet__amount-btn-icon-fill"
          />
        </button>
      </div>

      <button
        className="dice-bet__range-block"
        type="button"
        onClick={toggleRange}
      >
        <div
          className={`dice-bet__range-button ${
            !up ? 'dice-bet__range-button_selected' : ''
          }`}
        >
          Under
        </div>
        <div
          className={`dice-bet__range-button ${
            up ? 'dice-bet__range-button_selected' : ''
          }`}
        >
          Over
        </div>
      </button>
    </div>
  );
}

export default DiceBet;
