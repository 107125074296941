import { useState, useEffect, useCallback } from 'react';
import {
  useTonConnectUI,
  useTonWallet,
  useTonConnectModal,
} from '@tonconnect/ui-react';
import { address, Address, TonClient } from '@ton/ton';
import {
  MEGA_DICE_ADDRESS,
  TON_API_KEY,
  TON_TRANSACTION_STATUS,
  TON_URL,
} from '../utils/constants';
import { parseTx } from '../utils/utilis';
import useTonBalance from './useTonBalance';

function useTon() {
  const [tonConnectUI] = useTonConnectUI();
  const connected = tonConnectUI.connected;
  const wallet = useTonWallet();
  const { state, open, close } = useTonConnectModal();
  const balance = useTonBalance(wallet);
  const game_balance = useTonBalance({ account: { address: MEGA_DICE_ADDRESS } }); // TODO: Make it parse it from api WebSocket

  const [transactionStatus, setTransactionStatus] = useState(
    TON_TRANSACTION_STATUS.NONE
  );

  // const fetchBalance = useCallback(async () => {
  //   if (wallet?.account?.address && tonConnectUI) {
  //     try {
  //       const response = await fetch(
  //         `${TON_URL}/getAddressBalance?address=${wallet.account.address}`
  //       );
  //       const data = await response.json();
  //       if (data.ok) {
  //         setBalance(parseInt(data.result) / 1e9); // Convert from nanoTON to TON
  //       }
  //     } catch (error) {
  //       console.error('Error fetching balance:', error);
  //     }
  //   }
  // }, [wallet, tonConnectUI]);

  // useEffect(() => {
  //   if (wallet && tonConnectUI) {
  //     fetchBalance();
  //     // Set up an interval to periodically fetch the balance
  //     const intervalId = setInterval(fetchBalance, 10000); // every 10 seconds
  //     return () => clearInterval(intervalId);
  //   }
  // }, [wallet, tonConnectUI, fetchBalance]);

  async function sendTransaction(messages) {
    if (!tonConnectUI) return;
    setTransactionStatus(TON_TRANSACTION_STATUS.PENDING);
    try {
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60, // Valid for 60 seconds
        messages,
      };
      await tonConnectUI.sendTransaction(transaction);
      setTransactionStatus(TON_TRANSACTION_STATUS.SUCCESS);
      return await getTransactions();
    } catch (error) {
      console.error('Error sending transaction:', error);
      setTransactionStatus(TON_TRANSACTION_STATUS.DECLINED);
    }
  }

  async function retry(fn, options) {
    for (let i = 0; i < options.retries; i++) {
      console.log(`Retry ${i + 1} of ${options.retries}`);
      try {
        return await fn();
      } catch (error) {
        if (i === options.retries - 1) throw error;
        await new Promise((resolve) => setTimeout(resolve, options.delay));
      }
    }
  }

  async function getTransactions() { // TODO: Test option with WebSocket tonapi.io
    const tonClient = new TonClient({
      endpoint: `${TON_URL}/jsonRPC`,
      apiKey: TON_API_KEY,
    });

    async function getTx() {
      const myAddress = Address.parse(MEGA_DICE_ADDRESS); // Address to fetch transactions from
      // TODO: SHOULD NOT BE SET HERE AS MENTIONED PREVIOUSLY
      //  as mentioned should be located right at the top of the received tx or else it causes errors
      //  Possible error example:
      //   1) User requests bet
      //   2) System sends notification to tonconnect for signature
      //   3) BEFORE signature is created user hides website (not closes the window but e.g. opens another app)
      //   4) Signature is created and user waits a moment to return to website (just like 10-15sec)
      //   5) Results in an infinite time bet loading
      const initTime = Math.floor(Date.now() / 1000);

      return retry(
        async () => {
          const transactions = await tonClient.getTransactions(myAddress, {
            limit: 10,
          });
          for (const tx of transactions) {
            const inMsg = tx.inMessage;
            const inBOC = inMsg?.body;
            if (typeof inBOC === 'undefined') {
              console.log('Invalid external message');
              continue;
            }

            if (tx.now >= initTime - 3 || tx.now >= initTime + 3) {
              for (let i = 0; i < tx.outMessagesCount; i++) {
                const gameResult = parseTx(
                  tx.outMessages.get(i).body.toBoc()
                );
                !gameResult && console.log('no result');
                if (
                  gameResult?.betOwner?.toRawString() === wallet.account.address
                ) {
                  console.log(gameResult);
                  return gameResult;
                }
              }
            }
          }

          throw new Error('Transaction not found');
        },
        { retries: 60, delay: 1000 }
      );
    }

    return await getTx();
  }

  return {
    tonConnectUI,
    wallet,
    balance,
    connected,
    state,
    open,
    close,
    transactionStatus,
    setTransactionStatus,
    sendTransaction,
    game_balance,
  };
}

export default useTon;
