import ResultNumber from '../ResultNumber/ResultNumber';
import './DiceResult.css';

function DiceResult({ slot1, slot2, slot3, slot4, isWin, isLost, isFast }) {
  return (
    <div className="dice-result">
      <div className="dice-result__number-box">
        <ResultNumber
          arr={slot1.arr}
          rolling={slot1.rolling}
          rollingEnd={slot1.rollingEnd}
          {...{ isWin, isLost, isFast }}
        />
        <ResultNumber
          arr={slot2.arr}
          rolling={slot2.rolling}
          rollingEnd={slot2.rollingEnd}
          {...{ isWin, isLost, isFast }}
        />
      </div>

      <p className="dice-result__comma">.</p>

      <div
        className="dice-result__number-box"
        // onClick={() => {setSkipAutoPlay(!skipAutoPlay)}}
      >
        <ResultNumber
          arr={slot3.arr}
          rolling={slot3.rolling}
          rollingEnd={slot3.rollingEnd}
          {...{ isWin, isLost, isFast }}
        />
        <ResultNumber
          arr={slot4.arr}
          rolling={slot4.rolling}
          rollingEnd={slot4.rollingEnd}
          {...{ isWin, isLost, isFast }}
        />
      </div>
    </div>
  );
}

export default DiceResult;
