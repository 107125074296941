export const TON_URL = 'https://toncenter.com/api/v2';
export const TON_API_KEY =
  '29e3a81427a12ce74332c750d3e94b953928770953c1cf0d89db01b39a5e5278';

export const DEMO_CURRENCY = 'DEMO';
export const TON_CURRENCY = 'TON';

export const TON_DECIMALS = 9;
export const DEMO_DECIMALS = 9;
export const INIT_DEMO_BALANCE = 1000;
export const MIN_DEMO_BET_NUMBER = 10;
export const MAX_DEMO_BET_NUMBER = 1000;
export const DEMO_BET_STEP = 1;

export const START_BET_MEGA_DICE = 50.0;
export const MIN_BET_MEGA_DICE = 4;
export const MAX_BET_MEGA_DICE = 97;
export const NUMBERS_ARR = [6, 7, 8, 9, 0, 1, 2, 3, 4, 5];
export const BET_STEP = 0.1;

export const MIN_AUTOPLAY_NUMBER = 1;
export const MAX_AUTOPLAY_NUMBER = 50;

export const DEMO_ROUNDED_DECIMALS = 2;
export const TON_ROUNDED_DECIMALS = 4;
export const GAME_OPTIONS_ROUNDED_DECIMALS = 2;

export const NETWORK_FEE = 0.15

export const TON_TRANSACTION_STATUS = {
  NONE: undefined,
  PENDING: 'pending',
  SUCCESS: 'success',
  DECLINED: 'declined',
};

export const MEGA_DICE_ADDRESS =
  'EQC0pJgogwvOOPCV2mr-ztynK3Fc7ASATjZFDYiIZFv-4srT';

  export const NOTIFY_API_URL = 'https://tg-function.yc-cabatest.ru/CABA_NOTIFIER/PARALIQ_TON_GAMES/'