import { useEffect, useState } from 'react';
import { Howl } from 'howler';
import main_music_theme from '../sounds/beat_final.wav';

const useSounds = () => {
  const [isMusicOn, setMusicOn] = useState(false);
  const [isMusicPlaying, setMusicPlaying] = useState(false);
  const [musicId, setMusicId] = useState(0);
  const [music, setMusic] = useState(null);
  const [isSoundOn, setSoundOn] = useState(
    localStorage.getItem('isSoundOn') !== null
      ? JSON.parse(localStorage.getItem('isSoundOn'))
      : false
  );

  // set and control music
  useEffect(() => {
    const audio = new Howl({
      src: [main_music_theme],
      loop: true,
      volume: 0.3,
      onplay: function (id) {
        setMusicId(id);
        setMusicPlaying(true);
      },
      onfade: function () {
        setMusicPlaying(false);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on id ${id}:`, error);
      },
    });

    setMusic(audio);
  }, []);

  const toggleMusic = () => {
    if (music === null) return;

    setMusicOn(!isMusicOn);
    if (!isMusicOn && !isMusicPlaying) {
      music.play();
      setMusicPlaying(true);
    } else {
      music.fade(0.3, 0, 500, musicId);
    }
  };

  // toggle sounds state
  function toggleSound() {
    let newValue = !isSoundOn;
    setSoundOn(newValue);
    localStorage.setItem('isSoundOn', newValue);
  }

  const playGameSound = (
    audio,
    isAudioPlaying,
    isGameOn,
    isFast = false,
    speed = 1
  ) => {
    if (audio === null) return;

    if (isSoundOn && !isAudioPlaying && isGameOn) {
      audio.play();
      audio.mute(false);
      isFast ? audio.rate(speed) : audio.rate(1);
    }
  };

  const fadeGameAudio = (audio, audioId) => {
    if (audio === null) return;

    audio.fade(1, 0, 300, audioId);
  };

  // const stopGameAudio = (audio, audioId) => {
  //   if (audio === null) return;

  //   audio.stop(audioId);
  // };

  const playMuteGameAudio = (audio, isFast = false, speed = 1) => {
    if (audio === null) return;

    audio.play();
    audio.mute(true);
    isFast ? audio.rate(speed) : audio.rate(1);
  };

  const muteGameAudio = (audio, audioId) => {
    if (audio === null) return;

    audio.mute(true, audioId);
  };

  const unmuteGameAudio = (audio, audioId) => {
    if (audio === null) return;

    audio.mute(false, audioId);
  };

  return {
    isSoundOn,
    toggleSound,
    isMusicOn,
    toggleMusic,
    playGameSound,
    fadeGameAudio,
    // stopGameAudio,
    playMuteGameAudio,
    muteGameAudio,
    unmuteGameAudio,
  };
};

export default useSounds;
