export const SoundIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_287_3739)">
      <path
        className={fillClassName}
        d="M6.02825 0.58928L3.96747 1.91225C3.42505 2.26 2.79451 2.44538 2.15019 2.44653H1.96847C1.58289 2.44653 1.2131 2.5997 0.940461 2.87234C0.667817 3.14499 0.514648 3.51477 0.514648 3.90035V6.80798C0.514648 7.19356 0.667817 7.56334 0.940461 7.83599C1.2131 8.10863 1.58289 8.2618 1.96847 8.2618H2.15019C2.79558 8.26631 3.42618 8.45549 3.96747 8.80698L6.0337 10.13C6.27748 10.2857 6.55873 10.3729 6.84786 10.3824C7.13699 10.3918 7.42332 10.3232 7.67677 10.1838C7.93022 10.0443 8.14141 9.83913 8.28815 9.58983C8.43488 9.34052 8.51175 9.05628 8.51065 8.767V1.94496C8.51107 1.65545 8.43342 1.37118 8.28588 1.12208C8.13834 0.872987 7.92636 0.66828 7.67226 0.529516C7.41817 0.390752 7.13137 0.323065 6.84205 0.333583C6.55273 0.3441 6.27159 0.432433 6.02825 0.58928V0.58928Z"
        fill="var(--text-color)"
        fillOpacity="0.5"
      />
      <path
        className={fillClassName}
        d="M10.5535 2.69549C10.4598 2.58641 10.3269 2.51871 10.1836 2.50714C10.0404 2.49557 9.89831 2.54106 9.7884 2.6337C9.67824 2.72686 9.6095 2.85989 9.59724 3.00363C9.58498 3.14737 9.6302 3.29012 9.72298 3.40059C10.1476 3.97237 10.3804 4.66382 10.3881 5.37597C10.3807 6.08761 10.1479 6.7786 9.72298 7.34952C9.62977 7.45954 9.58393 7.602 9.59551 7.74573C9.60709 7.88945 9.67514 8.02274 9.78476 8.11641C9.88588 8.20292 10.0152 8.24946 10.1482 8.24725C10.2271 8.24704 10.305 8.2297 10.3765 8.19644C10.4481 8.16318 10.5115 8.1148 10.5626 8.05462C11.1574 7.28793 11.4825 6.34634 11.4875 5.37597C11.4802 4.40393 11.1518 3.46157 10.5535 2.69549V2.69549Z"
        fill="var(--text-color)"
        fillOpacity="0.5"
      />
      <path
        className={fillClassName}
        d="M12.3489 1.76704C12.2583 1.65472 12.127 1.58286 11.9836 1.56719C11.8402 1.55152 11.6964 1.59333 11.5838 1.68345C11.4712 1.77371 11.399 1.90492 11.383 2.04833C11.367 2.19174 11.4085 2.33565 11.4984 2.44852C12.1015 3.30608 12.4274 4.32777 12.4325 5.37614C12.4272 6.42392 12.1012 7.44495 11.4984 8.30195C11.4083 8.41459 11.3665 8.55836 11.3821 8.70176C11.3978 8.84516 11.4697 8.9765 11.582 9.06702C11.6789 9.14476 11.7994 9.18707 11.9236 9.18696C12.0052 9.18702 12.0858 9.16876 12.1594 9.13354C12.233 9.09831 12.2977 9.04701 12.3489 8.98343C13.1112 7.9351 13.5222 6.67236 13.5228 5.37614C13.5219 4.07941 13.111 2.81616 12.3489 1.76704V1.76704Z"
        fill="var(--text-color)"
        fillOpacity="0.5"
      />
      <path
        className={fillClassName}
        d="M14.0912 0.414825C13.9961 0.307324 13.8624 0.241686 13.7191 0.23216C13.5759 0.222633 13.4347 0.269987 13.3261 0.363942C13.2173 0.458379 13.1503 0.59206 13.1397 0.735762C13.1292 0.879465 13.1759 1.0215 13.2698 1.13083C13.2825 1.14537 14.5419 2.63917 14.5419 5.37598C14.5419 8.11279 13.288 9.60477 13.2698 9.61931C13.1755 9.72817 13.1281 9.86993 13.138 10.0136C13.1479 10.1573 13.2142 10.2912 13.3225 10.3862C13.4231 10.474 13.5524 10.5219 13.686 10.5207C13.7631 10.521 13.8394 10.505 13.9099 10.4737C13.9804 10.4424 14.0434 10.3964 14.0948 10.3389C14.1584 10.2681 15.6305 8.54893 15.6305 5.37961C15.6305 2.21029 14.1548 0.487516 14.0912 0.414825Z"
        fill="var(--text-color)"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_287_3739">
        <rect
          className={fillClassName}
          width="15.1252"
          height="10.2912"
          fill="var(--text-color)"
          transform="translate(0.5 0.229492)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SoundOffIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M7.67755 0.874052L4.80479 2.71505C4.04964 3.19818 3.17243 3.45608 2.27595 3.45852H2.02307C1.48652 3.45852 0.971941 3.67167 0.592542 4.05107C0.213143 4.43047 0 4.94504 0 5.48159V9.51762C0 10.0542 0.213143 10.5687 0.592542 10.9481C0.971941 11.3275 1.48652 11.5407 2.02307 11.5407H2.27595C3.17243 11.5431 4.04964 11.801 4.80479 12.2842L7.67755 14.1277C8.01606 14.344 8.40648 14.4652 8.80795 14.4788C9.20941 14.4924 9.60715 14.3978 9.95952 14.205C10.3119 14.0121 10.6059 13.7281 10.8109 13.3826C11.0158 13.0371 11.1241 12.6429 11.1244 12.2412V2.76057C11.1249 2.35854 11.0171 1.96379 10.8124 1.6178C10.6077 1.27182 10.3135 0.987366 9.96085 0.794347C9.60819 0.601329 9.21004 0.506869 8.80826 0.520894C8.40647 0.534919 8.01589 0.656912 7.67755 0.874052Z"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
    <path
      className={fillClassName}
      d="M16.9554 7.5075L18.6396 5.82076C18.7736 5.67695 18.8466 5.48673 18.8431 5.29019C18.8396 5.09365 18.76 4.90612 18.621 4.76713C18.482 4.62813 18.2945 4.54851 18.0979 4.54504C17.9014 4.54157 17.7112 4.61453 17.5674 4.74854L15.8806 6.43527L14.1964 4.74854C14.0526 4.61453 13.8624 4.54157 13.6659 4.54504C13.4693 4.54851 13.2818 4.62813 13.1428 4.76713C13.0038 4.90612 12.9242 5.09365 12.9207 5.29019C12.9172 5.48673 12.9902 5.67695 13.1242 5.82076L14.8084 7.5075L13.1242 9.1917C12.9821 9.33395 12.9023 9.52677 12.9023 9.72782C12.9023 9.92886 12.9821 10.1217 13.1242 10.2639C13.2665 10.406 13.4593 10.4858 13.6603 10.4858C13.8614 10.4858 14.0542 10.406 14.1964 10.2639L15.8806 8.57972L17.5674 10.2639C17.7096 10.406 17.9024 10.4858 18.1035 10.4858C18.3045 10.4858 18.4974 10.406 18.6396 10.2639C18.7817 10.1217 18.8615 9.92886 18.8615 9.72782C18.8615 9.52677 18.7817 9.33395 18.6396 9.1917L16.9554 7.5075Z"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
  </svg>
);

export const MusicIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0.75C3.53404 0.75 3.14252 1.06869 3.0315 1.5H3V1.75V3V6.87803C2.76542 6.79512 2.51298 6.75 2.25 6.75C1.00736 6.75 0 7.75736 0 9C0 10.2426 1.00736 11.25 2.25 11.25C3.49264 11.25 4.5 10.2426 4.5 9V3H10.5V5.37803C10.2654 5.29512 10.013 5.25 9.75 5.25C8.50736 5.25 7.5 6.25736 7.5 7.5C7.5 8.74264 8.50736 9.75 9.75 9.75C10.9926 9.75 12 8.74264 12 7.5V3V1.75V1.5H11.9685C11.8575 1.06869 11.466 0.75 11 0.75H4Z"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
  </svg>
);

export const MusicOffIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6077 0.388849C14.2819 0.0630924 13.7538 0.0630917 13.428 0.388848L12.0661 1.75077C12.0521 1.7502 12.0381 1.74991 12.0241 1.74991H5.02408C4.55812 1.74991 4.16659 2.0686 4.05558 2.49991H4.02408V2.74991V3.99991V7.87794C3.78949 7.79502 3.53705 7.74991 3.27408 7.74991C2.03144 7.74991 1.02408 8.75727 1.02408 9.99991C1.02408 10.7598 1.40081 11.4318 1.97771 11.8391L1.09979 12.7171C0.774029 13.0428 0.77403 13.571 1.09979 13.8967C1.42554 14.2225 1.9537 14.2225 2.27946 13.8967L14.6077 1.56852C14.9334 1.24276 14.9334 0.714606 14.6077 0.388849ZM5.52408 8.29277L9.81694 3.99991H5.52408V8.29277ZM10.7741 10.7499C9.87144 10.7499 9.09295 10.2184 8.73451 9.45127L13.0241 5.16171V8.49991C13.0241 9.74255 12.0167 10.7499 10.7741 10.7499Z"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
  </svg>
);

export const BurgerBtnIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="14"
    height="6"
    viewBox="0 0 14 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className={fillClassName}
      width="14"
      height="2"
      rx="1"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
    <rect
      className={fillClassName}
      y="4"
      width="10"
      height="2"
      rx="1"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
  </svg>
);

export const CloseIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
      fill="var(--text-color)"
    />
  </svg>
);

export const ArrowBtnIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M0.287109 6.99999L6.29711 13.01L7.71111 11.596L3.11111 6.99599L7.71111 2.39599L6.29711 0.98999L0.287109 6.99999Z"
      fill="var(--text-color)"
    />
  </svg>
);
