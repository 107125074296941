import { Cell, fromNano } from '@ton/ton';
import { TON_DECIMALS } from './constants';

export function prettyValue(n, decimals) {
  if (!n) return null
  return Number(n.toFixed(decimals));
}

export function toNano(value) {
  return value * Math.pow(10, TON_DECIMALS);
}

export function parseTx(tx) {
  if (!tx) return null;
  let parsedTx = {};

  try {
    const cell = Cell.fromBoc(tx)[0];
    const slice = cell.beginParse();

    // Load the values from the slice
    parsedTx.op = slice.loadUint(32);
    parsedTx.queryId = slice.loadUintBig(64);
    parsedTx.orderSeqno = slice.loadUintBig(256);
    parsedTx.betOwner = slice.loadAddress();
    parsedTx.betAmount = fromNano(slice.loadCoins());
    parsedTx.autorollAmount = slice.loadUint(8);
    parsedTx.paidAmount = Number(fromNano(slice.loadUint(256)));

    const gameDataSlice = slice.loadRef().beginParse();
    parsedTx.gameData = {
      up: gameDataSlice.loadUint(1),
      position: gameDataSlice.loadUint(16),
    };

    parsedTx.resultNumbers = [];
    const resultNumbersSlice = slice.loadRef().beginParse();
    for (let i = 0; i < parsedTx.autorollAmount; i++) {
      parsedTx.resultNumbers.push(resultNumbersSlice.loadUint(16));
    }
  } catch (error) {
    // console.error('Error parsing transaction result:', error);
    return null;
  }
  return parsedTx;
}

// parse game response megadice
export function parseGameResultMegaDice(result, decimals) {
  const games = [];

  result.resultNumbers.forEach((item) => {
    let winning = 0;
    let outcome = 0;

    const up = result.gameData.up;
    const position = result.gameData.position;
    const amount = result.paidAmount;
    const sentValue = result.betAmount;

    if ((!up && item < position) || (up && item > position)) {
      outcome = up ? 10000 - position : position;
      winning = ((amount / result.resultNumbers.length) * 10000) / outcome;
    }

    const gameResult = {
      address: result.betOwner,
      order_seqno: result.orderSeqno,
      sent_value: prettyValue(
        sentValue / result.resultNumbers.length,
        decimals
      ),
      bet_amount: prettyValue(amount / result.resultNumbers.length, decimals),
      up: up,
      position: Number(position),
      payout_amount: prettyValue(winning, decimals),
      payout_real_amount: winning,
      random_value: item,
      new: true,
    };

    games.push(gameResult);
  });

  console.log('game result:', games);
  return games;
}


export function formatBetValue(betValue) {
  // Округляем до 4 знаков, отсекая лишние
  const roundedValue = Math.floor(betValue * 10000) / 10000;
  // Преобразуем к числу с помощью parseFloat, чтобы убрать лишние нули
  return parseFloat(roundedValue.toFixed(4));
}