import { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import Slider from '@mui/material/Slider';
import bet_buttons_sound from '../../assets/sounds/bet_buttons_sound.wav';
import autoplay_slider_sound from '../../assets/sounds/autoplay_slider_sound.wav';
import demo from '../../assets/icons/jellybet-currency-icon.png';
import ton from '../../assets/icons/ton_symbol.png';
import {
  MIN_AUTOPLAY_NUMBER,
  MAX_AUTOPLAY_NUMBER,
  MIN_DEMO_BET_NUMBER,
  GAME_OPTIONS_ROUNDED_DECIMALS,
  BET_STEP,
  DEMO_BET_STEP,
  TON_ROUNDED_DECIMALS,
  DEMO_ROUNDED_DECIMALS,
  NETWORK_FEE,
} from '../../assets/utils/constants';
import './GameOptions.css';
import useAnimatedNumber from '../../assets/hooks/useAnimatedNumber';
import useSounds from '../../assets/hooks/useSounds';
import { formatBetValue, prettyValue } from '../../assets/utils/utilis';

function GameOptions({
  payout,
  payoutText = 'Payout',
  chance,
  btnText,
  onPlayBtnClick,
  rolling,
  isBlocked,
  minMaxBet,
  bet,
  setBet,
  autoplayAmount,
  setAutoplayAmount,
  isAutoplay = true,
  demoBalance,
  queue,
  isMaxBet,
  isMinBet,
  isDemo,
  autoplayText = 'Autoplay',
  balance,
  connected,
  openTonConnectModal,
}) {
  const { isSoundOn } = useSounds();
  const buttonsAudio = useMemo(() => new Audio(bet_buttons_sound), []);
  const autoplayAudio = useMemo(() => new Audio(autoplay_slider_sound), []);
  const { rounded: chanceValue } = useAnimatedNumber(
    chance,
    GAME_OPTIONS_ROUNDED_DECIMALS
  );
  const { rounded: payoutValue } = useAnimatedNumber(
    payout,
    GAME_OPTIONS_ROUNDED_DECIMALS
  );
  const [writtenBet, setWrittenBet] = useState(null);
  const [autoPlayAmountWritten, setAutoPlayAmountWriten] = useState(
    autoplayAmount.toString()
  );
  const [isDisabled, setDisabled] = useState(false);
  const [isBetBtnDisabled, setBetBtnDisabled] = useState({
    increase: false,
    reduce: false,
    min: false,
    max: false,
    multiply: false,
    divide: false,
  });
  const decimals = isDemo ? DEMO_ROUNDED_DECIMALS : TON_ROUNDED_DECIMALS;
  const step = isDemo ? DEMO_BET_STEP : BET_STEP;

  // useEffect(() => {
  //   if (localStorage.getItem('bet') !== null) return;
  //   if (
  //     balance &&
  //     connected &&
  //     typeof balance === 'number' &&
  //     minMaxBet.min > 0 &&
  //     minMaxBet.max === 0
  //   ) {
  //     setBet(minMaxBet.min);
  //   }
  //   if (!balance || !connected) {
  //     setBet(MIN_DEMO_BET_NUMBER);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [minMaxBet, balance, connected]);

  useEffect(() => {
    // console.log({ queue });
    console.log({ bet, bet_bool: bet === 0 })
    if (bet === 0) {
      setDisabled(false);
    } else {
      if (queue && queue.length > 0) {
        setDisabled(true);
      } else {
        if (isAutoplay) {
          if (
            autoPlayAmountWritten.length > 0 &&
            autoplayAmount >= MIN_AUTOPLAY_NUMBER &&
            autoplayAmount <= MAX_AUTOPLAY_NUMBER
          ) {
            setDisabled(
              balance && !isDemo
                ? balance < bet * autoplayAmount
                : demoBalance < bet * autoplayAmount
            );
          } else {
            setDisabled(true);
          }
        } else {
          setDisabled(
            balance && connected && !isDemo ? balance < bet : demoBalance < bet
          );
        }
      }

    }
  }, [
    bet,
    isAutoplay,
    autoplayAmount,
    autoPlayAmountWritten,
    demoBalance,
    queue,
    connected,
    isDemo,
    balance,
  ]);

  useEffect(() => {
    if (balance && connected && !isDemo) {
      setBetBtnDisabled((prevObj) => ({
        ...prevObj,
        increase: bet === minMaxBet.max || bet === balance,
        reduce: bet === minMaxBet.min,
        min: bet === minMaxBet.min,
        max: bet === minMaxBet.max || bet === balance,
        multiply: bet === minMaxBet.max || bet === balance,
        divide: bet === minMaxBet.min,
      }));
    } else {
      setBetBtnDisabled((prevObj) => ({
        ...prevObj,
        increase: bet === minMaxBet.max || bet === demoBalance,
        reduce: bet === minMaxBet.min,
        min: bet === minMaxBet.min,
        max: bet === minMaxBet.max || bet === demoBalance,
        multiply: bet === minMaxBet.max || bet === demoBalance,
        divide: bet === minMaxBet.min,
      }));
    }
  }, [bet, minMaxBet, demoBalance, connected, isDemo, balance]);

  function playSound(audio) {
    if (isSoundOn) {
      audio.play().catch((err) => {
        console.log(err);
      });
    }
  }

  function handleIncreaseBet() {
    const newBet = Math.min(
      bet + step,
      minMaxBet.max,
      demoBalance > 0 ? demoBalance : minMaxBet.max
    );
    setWrittenBet(null);
    setBet(newBet);
    localStorage.setItem('bet', newBet);
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function handleReduceBet() {
    const newBet = Math.max(bet - step, minMaxBet.min);
    setWrittenBet(null);
    setBet(newBet);
    localStorage.setItem('bet', newBet);
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function setMinBet() {
    const newBet = minMaxBet.min;
    setWrittenBet(null);
    setBet(newBet);
    localStorage.setItem('bet', newBet);
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function setMaxBet() {
    const newBet = formatBetValue(Math.min(
      minMaxBet.max,
      balance && connected && !isDemo
        ? balance > 0
          ? balance
          : minMaxBet.min
        : demoBalance > 0
          ? demoBalance
          : minMaxBet.min
    ));
    setWrittenBet(null);
    setBet(newBet);
    localStorage.setItem('bet', newBet);
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function handleMultiplyBet() {
    const newBet = Math.min(
      bet * 2,
      minMaxBet.max,
      balance && connected && !isDemo
        ? balance > 0
          ? balance
          : minMaxBet.max
        : demoBalance > 0
          ? demoBalance
          : minMaxBet.max
    );
    setWrittenBet(null);
    setBet(newBet);
    localStorage.setItem('bet', newBet);
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function handleDivideBet() {
    const newBet = Math.max(bet / 2, minMaxBet.min);
    setWrittenBet(null);
    setBet(newBet);
    localStorage.setItem('bet', newBet);
    if (newBet !== bet) playSound(buttonsAudio);
  }

  function handleBetChange(evt) {
    const initValue = evt.target.value
      .replace(/[^.\d]/g, '')
      .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
    let value = 0;
    if (initValue && Number(initValue) > 0) {
      value = Number(initValue);
    }
    setWrittenBet(initValue);
    setBet(value);
  }

  function checkBetChange(evt) {
    const value = evt.target.value;
    let newBet;
    if (value && !isNaN(Number(value))) {
      newBet = Number(value);
    } else {
      newBet = Number(minMaxBet.min);
    }
    setBet(newBet);
    localStorage.setItem('bet', newBet);
  }

  function handleAmountChange(evt) {
    const value = evt.target.value.replace(/[^0-9]/g, '');
    let writenValue = value;
    if (value) {
      let newValue =
        Number(value) > MAX_AUTOPLAY_NUMBER
          ? MAX_AUTOPLAY_NUMBER
          : Number(value);
      setAutoplayAmount(newValue);
      writenValue = newValue.toString();
    }

    setAutoPlayAmountWriten(writenValue);
    playSound(autoplayAudio);
  }

  function handleSliderChange(evt) {
    const value = evt.target.value;
    setAutoplayAmount(value);
    setAutoPlayAmountWriten(value.toString());
  }

  function stopSlider() {
    playSound(autoplayAudio);
  }

  return (
    <div className={`options ${isBlocked ? 'options_disabled' : ''}`}>
      <div className="options__payout-block">
        <div className="options__payout-text-block">
          <p className="options__block-title">{payoutText}</p>
          <p className="options__payout-numbers">
            x
            <motion.span className="options__payout-number">
              {payoutValue}
            </motion.span>
          </p>
        </div>

        <div className="options__payout-text-block">
          <p className="options__block-title">Chance</p>
          <p className="options__payout-numbers">
            <motion.span className="options__payout-number">
              {chanceValue}
            </motion.span>
            %
          </p>
        </div>
      </div>

      <div className="options__bet-block">
        <p className="options__block-title">Your bet</p>

        <div className="options__set-bet-block">
          <button
            className={`options__bet-btn ${isBetBtnDisabled.reduce ? 'options__bet-btn_disabled' : ''
              }`}
            type="button"
            aria-label="Reduce the bet size"
            onClick={handleReduceBet}
          >
            -
          </button>
          <div className="options__bet-box">
            <img
              className="options__currency-icon"
              src={isDemo ? demo : ton}
              alt="Network icon"
            />
            <input
              className="options__bet-input"
              id="bet"
              name="bet"
              type="text"
              autoComplete="off"
              value={
                writtenBet !== null ? writtenBet : prettyValue(bet, decimals)
              }
              onChange={handleBetChange}
              onBlur={checkBetChange}
            />
          </div>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.increase ? 'options__bet-btn_disabled' : ''
              }`}
            type="button"
            aria-label="Increase the bet size"
            onClick={handleIncreaseBet}
          >
            +
          </button>
        </div>

        <div className="options__bet-options">
          <button
            className={`options__bet-btn ${isBetBtnDisabled.min ? 'options__bet-btn_disabled' : ''
              }`}
            type="button"
            aria-label="Set minimal bet size"
            onClick={setMinBet}
          >
            Min
          </button>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.divide ? 'options__bet-btn_disabled' : ''
              }`}
            type="button"
            aria-label="Divide the bet by two"
            onClick={handleDivideBet}
          >
            /2
          </button>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.multiply ? 'options__bet-btn_disabled' : ''
              }`}
            type="button"
            aria-label="Multiply the bet by two"
            onClick={handleMultiplyBet}
          >
            x2
          </button>
          <button
            className={`options__bet-btn ${isBetBtnDisabled.max ? 'options__bet-btn_disabled' : ''
              }`}
            type="button"
            aria-label="Set maximal bet size"
            onClick={setMaxBet}
          >
            Max
          </button>
        </div>
      </div>

      <div className="options__autoplay-block">
        <div className="options__autoplay-switch-block">
          <div className="options__autoplay-label">
            <p className="options__block-title options__block-title_type_label">
              {autoplayText}
            </p>
          </div>

          <input
            className={`options__autoplay-input ${!isAutoplay ? 'options__autoplay-input_disabled' : ''
              }`}
            id="amount"
            name="amount"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={autoPlayAmountWritten}
            onChange={handleAmountChange}
            readOnly={!isAutoplay}
          />
        </div>

        <div className="options__slider-box">
          <Slider
            className={`options__autoplay-slider ${!isAutoplay ? 'options__autoplay-slider_disabled' : ''
              }`}
            aria-label="Autoplay"
            min={MIN_AUTOPLAY_NUMBER}
            max={MAX_AUTOPLAY_NUMBER}
            step={1}
            defaultValue={0}
            value={autoplayAmount}
            onChange={handleSliderChange}
            onChangeCommitted={stopSlider}
          />
          <div
            className={`options__slider-disabled ${!isAutoplay ? 'options__slider-disabled_visible' : ''
              }`}
          >
            <div className="options__slider-thumb" />
            <div className="options__slider-rail" />
          </div>
        </div>
      </div>

      {isDemo || (!isDemo && connected) ? (
        <div className="options__play-btn-box">
          <button
            className={`options__play-btn ${rolling ? 'options__play-btn_rolling' : ''
              } ${isDemo ? '' : isDisabled || isMaxBet || isMinBet || balance - NETWORK_FEE <= bet * autoplayAmount
                ? 'options__play-btn_disabled'
                : ''
              } ${isDemo ? '' : !bet || bet === 0 ? 'options__play-btn_disabled' : ''}`}
            type="button"
            onClick={onPlayBtnClick}
          >
            {isDemo ? btnText : balance - NETWORK_FEE > bet * autoplayAmount ? btnText : 'Not enought money'}
            <span className='options__play-btn_network-fee'>
              Network fee ~{NETWORK_FEE} TON
            </span>
          </button>
        </div>
      ) : (
        <div className="options__play-btn-box">
          <button
            className="options__play-btn"
            type="button"
            onClick={openTonConnectModal}
          >
            Connect
          </button>
        </div>
      )}
    </div>
  );
}

export default GameOptions;
