import { useEffect, useState } from 'react';
import './App.css';
import useTgMiniApp from '../../assets/hooks/useTgMiniApp';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSounds from '../../assets/hooks/useSounds';
import Header from '../Header/Header';
import { INIT_DEMO_BALANCE } from '../../assets/utils/constants';

import MegaDice from '../MegaDice/MegaDice';
import useTon from '../../assets/hooks/useTon';
import Loading from '../Loading/Loading';

// toast transitions
const SlideAndFade = cssTransition({
  enter: 'slide-in-right',
  exit: 'fade-out',
});

function App() {
  useTgMiniApp();
  useSounds();
  const {
    balance,
    tonConnectUI,
    connected,
    transactionStatus,
    sendTransaction,
    wallet,
    game_balance,
    open: openTonConnectModal,
  } = useTon();
  const [isDemo, setIsDemo] = useState(false);
  const [demoBalance, setDemoBalance] = useState(INIT_DEMO_BALANCE);
  const [isGamePlaying, setGamePlaying] = useState(false);
  const [isBlocked, setBlocked] = useState(false);

  function handleSwitchDemo() {
    setIsDemo((prevValue) => !prevValue);
  }

  const [preloaders, setPreloaders] = useState({
    app: true,
  })

  const { Telegram } = window;
  if (Telegram?.WebApp) {
    Telegram.WebApp.disableVerticalSwipes();
    // Get the stable viewport height
    const stableHeight = Telegram.WebApp.viewportHeight;
    if (stableHeight) {
      // Update the CSS variable for app height
      document.documentElement.style.setProperty('--app-height', `${stableHeight}px`);

      // Get the container element
      const container = document.getElementById('container');

      if (container) {
        // Enable scroll only if content exceeds the visible height
        if (container.scrollHeight > stableHeight) {
          container.style.overflowY = 'auto';
        } else {
          container.style.overflowY = 'hidden';
        }
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setPreloaders((prevState) => ({
        ...prevState,
        app: false
      }));
    }, 6000)
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app" id='container'>
      {preloaders.app ? <Loading /> : null}
      <Header
        isDemo={isDemo}
        handleSwitchDemo={handleSwitchDemo}
        isGamePlaying={isGamePlaying}
        isBlocked={isBlocked}
        demoBalance={demoBalance}
        setDemoBalance={setDemoBalance}
        {...{
          balance,
        }}
      />

      <MegaDice
        isGamePlaying={isGamePlaying}
        setGamePlaying={setGamePlaying}
        isBlocked={isBlocked}
        setBlocked={setBlocked}
        isDemo={isDemo}
        demoBalance={demoBalance}
        setDemoBalance={setDemoBalance}
        {...{
          balance,
          tonConnectUI,
          connected,
          transactionStatus,
          sendTransaction,
          wallet,
          game_balance,
          openTonConnectModal,
        }}
      />

      <ToastContainer
        className="app_toast-container app_toast-container_type_game"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        transition={SlideAndFade}
        theme="dark"
        closeOnClick={true}
        limit={6}
      />
    </div>
  );
}

export default App;
