import { useRef } from 'react';
import { toast } from 'react-toastify';
import ToastMsg from '../../components/ToastMsg/ToastMsg';
import {
  DeclinedIcon,
  LostIcon,
  MadeIcon,
  SentIcon,
  WonIcon,
  // CopyIcon,
} from '../icons/toastIcons';
import { DEMO_CURRENCY, TON_CURRENCY } from '../utils/constants';

const useToast = () => {
  const toastMadeId = useRef(null);
  const toastSentId = useRef(null);
  const toastReduceBetId = useRef(null);
  const toastIncreaseBetId = useRef(null);

  // notifications
  const displayLostMsg = (isFast) => {
    toast(
      <ToastMsg
        startMsg="Unfortunately, you"
        endMsg="your last bet"
        span="lost"
        text="Try again!"
      />,
      { icon: LostIcon, autoClose: isFast ? 2000 : true }
    );
  };

  const displayWonMsg = (win, isFast) => {
    toast(
      <ToastMsg msg="You won!" win={win} text="It will be deposited shortly" />,
      { icon: WonIcon, autoClose: isFast ? 2000 : true }
    );
  };

  const displayDeclinedMsg = () => {
    toast(<ToastMsg msg="Transaction is declined, please try again" />, {
      icon: DeclinedIcon,
      autoClose: true,
    });
  };

  const displayTxDoneMsg = () => {
    toast(<ToastMsg msg="Transaction is done!" />, {
      icon: MadeIcon,
      autoClose: true,
    });
  };

  const displayMadeMsg = () =>
    (toastMadeId.current = toast(
      <ToastMsg msg="Your bet is made. Please approve it in your wallet" />,
      { icon: MadeIcon, autoClose: false, closeOnClick: false }
    ));

  const dismissMadeMsg = () => toast.dismiss(toastMadeId.current);

  const displaySentMsg = () =>
    (toastSentId.current = toast(
      <ToastMsg msg="Transaction is sent, please wait for approval" />,
      {
        icon: SentIcon,
        autoClose: false,
        closeOnClick: false,
      }
    ));

  const dismissSentMsg = () => toast.dismiss(toastSentId.current);

  // const displayCopyMsg = () => {
  //   toast(<ToastMsg msg="Copied to clipboard" />, {
  //     icon: CopyIcon,
  //     className: "toast_type_copy",
  //     autoClose: true,
  //   });
  // };

  const displayIncreaseBetMsg = (minBet, isDemo) =>
    (toastIncreaseBetId.current = toast(
      <ToastMsg
        msg={`Min amount is ${minBet} ${
          !isDemo ? TON_CURRENCY : DEMO_CURRENCY
        }. Please, increase your bet`}
      />,
      {
        autoClose: false,
        closeOnClick: false,
      }
    ));

  const dismissIncreaseBetMsg = () => toast.dismiss(toastIncreaseBetId.current);

  const displayReduceBetMsg = (maxBet, isDemo) =>
    (toastReduceBetId.current = toast(
      <ToastMsg
        msg={`Max bet is ${maxBet} ${
          !isDemo ? TON_CURRENCY : DEMO_CURRENCY
        }. Please, reduce your bet`}
      />,
      {
        autoClose: false,
        closeOnClick: false,
      }
    ));

  const dismissReduceBetMsg = () => toast.dismiss(toastReduceBetId.current);

  return {
    displayLostMsg,
    displayWonMsg,
    displayDeclinedMsg,
    displayTxDoneMsg,
    displayMadeMsg,
    dismissMadeMsg,
    displaySentMsg,
    dismissSentMsg,
    displayIncreaseBetMsg,
    dismissIncreaseBetMsg,
    displayReduceBetMsg,
    dismissReduceBetMsg,
  };
};

export default useToast;
