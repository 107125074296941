export const LostIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM8 16C7.986 16 7.86 15.995 7 15.995H6V15.971C6 15.96 6 15.946 6 15.929C6 15.896 6 15.851 6.007 15.796C6.018 15.647 6.035 15.505 6.059 15.364C6.1339 14.9088 6.27541 14.467 6.479 14.053C6.75697 13.4813 7.18135 12.9935 7.709 12.639L7.723 12.63L7.739 12.618L7.754 12.61H7.76H7.767H7.772H7.779L7.79 12.603C8.45481 12.197 9.22113 11.9879 10 12C10.8096 11.9785 11.6071 12.1996 12.29 12.635C12.8172 12.99 13.2414 13.4777 13.52 14.049C13.7244 14.4626 13.8656 14.9045 13.939 15.36C13.971 15.548 13.985 15.699 13.991 15.792C13.991 15.836 13.997 15.88 13.998 15.925C13.998 15.942 13.998 15.956 13.998 15.967V15.987C13.998 15.987 13.958 15.987 12.998 15.987C12.092 15.987 11.998 15.987 11.998 15.987C11.998 15.987 11.998 15.958 11.998 15.928C11.998 15.873 11.985 15.788 11.967 15.682C11.9251 15.4229 11.8456 15.1712 11.731 14.935C11.6072 14.6753 11.4171 14.4529 11.18 14.29C10.8236 14.0797 10.4133 13.9788 10 14C9.58573 13.9818 9.17529 14.0862 8.82 14.3C8.5832 14.4632 8.39324 14.6856 8.269 14.945C8.15465 15.1813 8.07515 15.4329 8.033 15.692C8.0188 15.7734 8.00879 15.8555 8.003 15.938C8.003 15.966 8.003 15.986 8.003 15.996H8V16ZM6.5 10C5.67157 10 5 9.32843 5 8.5C5 7.67157 5.67157 7 6.5 7C7.32843 7 8 7.67157 8 8.5C8 9.32843 7.32843 10 6.5 10ZM13.493 9.986C12.6684 9.986 12 9.31756 12 8.493C12 7.66844 12.6684 7 13.493 7C14.3176 7 14.986 7.66844 14.986 8.493C14.9849 9.3171 14.3171 9.9849 13.493 9.986Z"
      fill="var(--text-color)"
    />
  </svg>
);

export const WonIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM10 16C8.42002 16.0267 6.9266 15.28 6 14C5.55008 13.3983 5.21141 12.721 5 12H15C15 12 15 12 15 12.008C14.7853 12.7252 14.4469 13.3994 14 14C13.0733 15.2799 11.5799 16.0266 10 16ZM6.5 10C5.67157 10 5 9.32843 5 8.5C5 7.67157 5.67157 7 6.5 7C7.32843 7 8 7.67157 8 8.5C8 9.32843 7.32843 10 6.5 10ZM13.493 9.986C12.6684 9.986 12 9.31756 12 8.493C12 7.66844 12.6684 7 13.493 7C14.3176 7 14.986 7.66844 14.986 8.493C14.9849 9.3171 14.3171 9.9849 13.493 9.986Z"
      fill="var(--text-color)"
    />
  </svg>
);

export const DeclinedIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M11.0003 21C6.95386 21.0024 3.3048 18.5659 1.75597 14.8276C0.207138 11.0893 1.06384 6.78606 3.9263 3.92598C6.4536 1.39868 10.1372 0.411662 13.5896 1.33672C17.0419 2.26177 19.7385 4.95836 20.6636 8.41072C21.5886 11.8631 20.6016 15.5467 18.0743 18.074C16.2018 19.955 13.6545 21.0086 11.0003 21ZM3.0003 11.172C3.04762 15.5732 6.64141 19.1095 11.0428 19.086C15.4443 19.0622 18.9998 15.4875 18.9998 11.086C18.9998 6.68449 15.4443 3.10975 11.0428 3.08598C6.64141 3.06244 3.04762 6.59874 3.0003 11V11.172ZM8.4093 15L7.0003 13.59L9.5903 11L7.0003 8.40998L8.4103 6.99998L11.0003 9.58998L13.5903 6.99998L15.0003 8.40998L12.4103 11L15.0003 13.59L13.5913 15L11.0003 12.41L8.4103 15H8.4093Z"
      fill="var(--lose-color)"
    />
  </svg>
);

export const MadeIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18ZM8 15L4 11L5.41 9.59L8 12.17L14.59 5.58L16 7L8 15Z"
      fill="var(--win-color)"
    />
  </svg>
);

export const SentIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.58378 14.4162 2.00496 10 2ZM15 11H9V5H11V9H15V11Z"
      fill="var(--text-color)"
      fillOpacity="0.5"
    />
  </svg>
);

export const CopyIcon = ({ mainClassName, fillClassName }) => (
  <svg
    className={mainClassName}
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={fillClassName}
      d="M12.9992 20.9999H2.99922C2.46329 21.0186 1.94369 20.8138 1.5645 20.4347C1.18532 20.0555 0.980591 19.5359 0.999215 18.9999V8.99995C0.980591 8.46402 1.18532 7.94442 1.5645 7.56524C1.94369 7.18605 2.46329 6.98132 2.99922 6.99995H6.99922V2.99995C6.98059 2.46402 7.18532 1.94442 7.5645 1.56524C7.94369 1.18605 8.46329 0.981324 8.99922 0.999948H18.9992C19.5351 0.981324 20.0547 1.18605 20.4339 1.56524C20.8131 1.94442 21.0178 2.46402 20.9992 2.99995V12.9999C21.0175 13.5358 20.8127 14.0552 20.4336 14.4343C20.0545 14.8134 19.5351 15.0183 18.9992 14.9999H14.9992V18.9999C15.0175 19.5358 14.8127 20.0552 14.4336 20.4343C14.0545 20.8134 13.5351 21.0183 12.9992 20.9999ZM2.99922 8.99995V18.9999H12.9992V14.9999H8.99922C8.46337 15.0183 7.94395 14.8134 7.56483 14.4343C7.18572 14.0552 6.98089 13.5358 6.99922 12.9999V8.99995H2.99922ZM8.99922 2.99995V12.9999H18.9992V2.99995H8.99922Z"
      fill="var(--text-color)"
    />
  </svg>
);
