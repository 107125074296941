import './Loading.css';
import load from '../../assets/images/load.webp';
import { motion } from "framer-motion"
import 'react-circular-progressbar/dist/styles.css';

function Loading({ success }) {

    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
            const delay = 0.5;
            return {
                pathLength: 1,
                opacity: 1,
                transition: {
                    pathLength: { delay, type: "spring", duration: 6.0, bounce: 0 },
                    opacity: { delay, duration: 0.5 }
                }
            };
        }
    };

    return (
        <motion.div
            animate={{ opacity: success ? 0 : 1 }}
            initial={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 6.0 }}
            className='loading'>
            {/* <img src={star} alt='' className='loading__star-bg'></img> */}
            <img src={load} alt='' className='loading__bg'></img>

            <div>
                <motion.svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    initial="hidden"
                    animate="visible"
                    className={'loading__svg'}
                >
                    <motion.circle
                        cx="25"
                        cy="25"
                        r="20"
                        stroke="var(--contrast-color)"
                        variants={draw}
                        custom={4}
                    />
                </motion.svg>
                <p className='loading__load-text'>Loading...</p>
            </div>


        </motion.div>
    );
}

export default Loading