import { useEffect, useState } from "react";
import TonWeb from 'tonweb';
import { TON_API_KEY, TON_URL } from "../utils/constants";
const tonweb = new TonWeb(new TonWeb.HttpProvider(`${TON_URL}/jsonRPC`, { apiKey: TON_API_KEY }));


function useTonBalance(wallet) {
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        let intervalId;

        async function fetchBalance() {
            if (wallet?.account?.address) {
                try {
                    const address = new TonWeb.utils.Address(wallet.account.address);
                    const balance = await tonweb.provider.getBalance(address.toString(true, true, true));
                    setBalance(balance ? parseInt(balance) / 1e9 : 0); // Convert from nanoTON to TON
                } catch (error) {
                    console.error('Error fetching balance:', error);
                }
            }
        }

        if (wallet) {
            fetchBalance();
            intervalId = setInterval(fetchBalance, 15000); // every 10 seconds
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [wallet]);

    return balance;
}

export default useTonBalance;