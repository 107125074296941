import { useState, useEffect } from 'react';

const useTgMiniApp = () => {
  const [tgMiniApp, setTgMiniApp] = useState(null);

  useEffect(() => {
    // Initialize Telegram Mini App
    const tgMiniApp = window.Telegram?.WebApp;

    if (tgMiniApp) {
      tgMiniApp.ready();
      tgMiniApp.expand();
      setTgMiniApp(tgMiniApp);
    }
  }, []);

  return { tgMiniApp };
};

export default useTgMiniApp;
