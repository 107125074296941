import { useEffect } from 'react';
import { useMotionValue, useTransform, animate } from 'framer-motion';

const useAnimatedNumber = (n, decimals) => {
  const count = useMotionValue(0);
  const rounded = useTransform(
    count,
    (latest) =>
      Math.round(latest * Math.pow(10, decimals)) / Math.pow(10, decimals)
  );

  useEffect(() => {
    const animation = animate(count, Number(n));
    return animation.stop;
  }, [count, n]);

  return { rounded };
};

export default useAnimatedNumber;
