import { NOTIFY_API_URL } from "../utils/constants";

class MainApi {
  // constructor({ baseUrl }) {
  //   MAIN_URL = baseUrl;
  // }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }
  
  sendNotification({ game_name, bet_amount, result, wallet }) {
    let params = {};
    if (game_name) params.game_name = game_name;
    if (bet_amount) params.bet_amount = bet_amount;
    if (result) params.result = result;
    if (wallet) params.wallet = wallet;

    return fetch(
      `${NOTIFY_API_URL}?` +
      new URLSearchParams(params),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

}



const mainApi = new MainApi({
  baseUrl: NOTIFY_API_URL,
});

export default mainApi;
