/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { beginCell } from '@ton/core';
import './MegaDice.css';
import { Howl } from 'howler';
import bet_slider_sound from '../../assets/sounds/bet_slider_sound2.wav';
import bet_buttons_sound from '../../assets/sounds/bet_buttons_sound.wav';
import megadice_game_sound from '../../assets/sounds/cube_game_sound.wav';
import GameOptions from '../GameOptions/GameOptions';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import DiceBet from './DiceBet/DiceBet';
import DiceResult from './DiceResult/DiceResult';
import {
  DEMO_CURRENCY,
  DEMO_DECIMALS,
  DEMO_ROUNDED_DECIMALS,
  MAX_BET_MEGA_DICE,
  MAX_DEMO_BET_NUMBER,
  MEGA_DICE_ADDRESS,
  MIN_AUTOPLAY_NUMBER,
  MIN_BET_MEGA_DICE,
  MIN_DEMO_BET_NUMBER,
  NETWORK_FEE,
  NUMBERS_ARR,
  START_BET_MEGA_DICE,
  TON_CURRENCY,
  TON_ROUNDED_DECIMALS,
  TON_TRANSACTION_STATUS,
} from '../../assets/utils/constants';
import useSounds from '../../assets/hooks/useSounds';
import useToast from '../../assets/hooks/useToast';
import {
  parseGameResultMegaDice,
  prettyValue,
  toNano,
} from '../../assets/utils/utilis';
import mainApi from '../../assets/api/MainApi';

function outcome(up, position) {
  if (up) {
    return 10000 - position;
  } else {
    return position;
  }
}

const minBetDefault = 0.1;
const gameFee = 0
const tonDefaultCommision = 0.04

function calculateMaxBet(maxPayout, up, position, fee) {
  return (
    ((maxPayout) * 10000 * outcome(up, Number((position * 100).toFixed(0)))) /
    ((10000 - fee) * 10000)
  );
}

function MegaDice({
  isGamePlaying,
  setGamePlaying,
  isBlocked,
  setBlocked,
  isDemo,
  demoBalance,
  setDemoBalance,
  balance,
  tonConnectUI,
  connected,
  transactionStatus,
  sendTransaction,
  wallet,
  game_balance,
  openTonConnectModal,
}) {

  const { isSoundOn, playGameSound, fadeGameAudio } = useSounds();
  const {
    displayLostMsg,
    displayWonMsg,
    displayDeclinedMsg,
    displayTxDoneMsg,
    displayMadeMsg,
    dismissMadeMsg,
    displaySentMsg,
    dismissSentMsg,
    displayIncreaseBetMsg,
    dismissIncreaseBetMsg,
    displayReduceBetMsg,
    dismissReduceBetMsg,
  } = useToast();
  const tokenDecimals = isDemo ? DEMO_ROUNDED_DECIMALS : TON_ROUNDED_DECIMALS;


  useEffect(() => {
    console.log('game blc upd', game_balance)
    setMaxPayout(game_balance * 800 / 10000)
  }, [game_balance])

  const [minBet, setMinBet] = useState(0);
  const [maxBet, setMaxBet] = useState(0);
  const [maxPayout, setMaxPayout] = useState(0);
  const [isMaxBet, setIsMaxBet] = useState(false);
  const [isMinBet, setIsMinBet] = useState(false);
  const [checkBet, setCheckBet] = useState(true);
  const [position, setPostion] = useState(START_BET_MEGA_DICE);
  const [up, setUp] = useState(true);
  const [isWin, setWin] = useState(false);
  const [isLost, setLost] = useState(false);
  const [isFast, setFast] = useState(false);
  const [spin, setSpin] = useState(false);
  const [chance, setChance] = useState(0);
  const [payout, setPayout] = useState(0);
  const [autoplayAmount, setAutoplayAmount] = useState(MIN_AUTOPLAY_NUMBER);
  const [queryID] = useState(undefined);
  const [betValue, setBetValue] = useState(undefined);
  const [prevBalance, setPrevBalance] = useState(balance);

  useEffect(() => {
    const balance_minus_fee = balance - tonDefaultCommision - NETWORK_FEE
    if (connected && balance_minus_fee > 0) {
      const maxBet = Math.min(
        calculateMaxBet(maxPayout, up, position, gameFee),
        balance_minus_fee / autoplayAmount
      );
      const newBetValue = Math.min(balance_minus_fee, maxBet);
      console.log({ balance_minus_fee, maxBet, newBetValue, bool: betValue >= prevBalance || !betValue })
      if (betValue >= prevBalance || !betValue) {
        setPrevBalance(balance_minus_fee)
        setBetValue(Math.max(newBetValue, minBetDefault));
      }
    } else if (isDemo) {
      if (betValue !== MIN_DEMO_BET_NUMBER) {
        setBetValue(MIN_DEMO_BET_NUMBER);
      }
    }
  }, [connected, isDemo, balance, maxBet, up, position, autoplayAmount]);
  console.log({ betValue })

  // track games
  const [gameQueue, setGameQueue] = useState([]);
  const [gameQueueInit, setGameQueueInit] = useState([]);
  const [gameQueueInitOffset, setGameQueueInitOffset] = useState(0);

  // number slots
  const [slot1, setSlot1] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });
  const [slot2, setSlot2] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });
  const [slot3, setSlot3] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });
  const [slot4, setSlot4] = useState({
    arr: NUMBERS_ARR,
    rolling: false,
    rollingEnd: false,
  });

  // set mega dice game sounds
  const sliderAudio = new Howl({
    src: [bet_slider_sound],
  });
  const buttonsAudio = new Howl({
    src: [bet_buttons_sound],
  });
  const [isGameAudioPlaying, setGameAudioPlaying] = useState(false);
  const [gameAudioId, setGameAudioId] = useState(0);
  const [gameAudio, setGameAudio] = useState(null);

  useEffect(() => {
    const audio = new Howl({
      src: [megadice_game_sound],
      onplay: function (id) {
        setGameAudioId(id);
        setGameAudioPlaying(true);
        audio.loop(true, id);
      },
      onfade: function (id) {
        setGameAudioPlaying(false);
        audio.loop(false, id);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on game sound id ${id}:`, error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on game sound id ${id}:`, error);
      },
    });

    setGameAudio(audio);
  }, []);

  // control games sounds
  useEffect(() => {
    if (gameAudio === null) return;

    spin
      ? playGameSound(gameAudio, isGameAudioPlaying, spin)
      : fadeGameAudio(gameAudio, gameAudioId);
  }, [spin]);

  useEffect(() => {
    if (gameAudio === null) return;

    isSoundOn
      ? playGameSound(gameAudio, isGameAudioPlaying, spin)
      : fadeGameAudio(gameAudio, gameAudioId);
  }, [isSoundOn]);

  const playSound = (audio) => {
    if (isSoundOn) audio.play();
  };

  // set number spinning state
  useEffect(() => {
    setSpin(slot1.rolling || slot2.rolling || slot3.rolling || slot4.rolling);
  }, [slot1.rolling, slot2.rolling, slot3.rolling, slot4.rolling]);

  // change chance and payout
  useEffect(() => {
    updateChanceAndPayout();
  }, [position, up]);

  const updateChanceAndPayout = async () => {
    const chance = ((!up ? position : 100 - position) * 100) / 10000;
    const payout = ((1 / chance) * (10000 - gameFee)) / 10000;
    setChance((chance * 100).toFixed(2));
    setPayout(payout.toFixed(2));
  };

  // min and max bet
  useEffect(() => {
    if (!betValue) return
    if (balance && connected && !isDemo) {
      updateMinBet(betValue);
      updateMaxPayout(up, position, autoplayAmount, betValue); // also max bet
    } else if (isDemo) {
      setMinBet(MIN_DEMO_BET_NUMBER);
      setIsMinBet(betValue < MIN_DEMO_BET_NUMBER);
      setMaxBet(MAX_DEMO_BET_NUMBER);
      setIsMaxBet(betValue > MAX_DEMO_BET_NUMBER);
    }
  }, [balance, connected, isDemo, betValue, autoplayAmount, position, up]);

  const updateMinBet = async (bet) => {
    const minBet = minBetDefault;
    setMinBet(minBet);
    setIsMinBet(bet < minBet);
  };

  const updateMaxPayout = async (up, position, autoplayAmount, bet) => {
    updateMaxBet(up, position, autoplayAmount, bet, maxPayout);
  };

  const updateMaxBet = async (up, position, autoplayAmount, bet, maxPayout) => {
    const balance_minus_fee = balance - tonDefaultCommision - NETWORK_FEE
    const calculated_max_bet = calculateMaxBet(maxPayout, up, position, gameFee)
    const calculated_max_payout_with_autoplay = calculateMaxBet(game_balance / autoplayAmount, up, position, gameFee)
    const maxBet = Math.min(
      calculated_max_payout_with_autoplay,
      calculated_max_bet,
      balance_minus_fee / autoplayAmount
    );
    setMaxBet(maxBet);
    setIsMaxBet(bet > maxBet);
  };

  // display messages
  useEffect(() => {
    if (isGamePlaying) return;
    isMaxBet
      ? displayReduceBetMsg(prettyValue(maxBet, tokenDecimals), isDemo)
      : dismissReduceBetMsg();
  }, [isMaxBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    isMinBet
      ? displayIncreaseBetMsg(prettyValue(minBet, tokenDecimals), isDemo)
      : dismissIncreaseBetMsg();
  }, [isMinBet, isGamePlaying, checkBet]);

  useEffect(() => {
    if (isGamePlaying) return;
    // dismissReduceBetMsg();
    // dismissIncreaseBetMsg();
    setCheckBet(isMinBet || isMaxBet ? !checkBet : checkBet);
  }, [isDemo, isGamePlaying]);

  useEffect(() => {
    if (transactionStatus === TON_TRANSACTION_STATUS.PENDING) {
      // dismissMadeMsg();
      displaySentMsg();
    } else if (transactionStatus === TON_TRANSACTION_STATUS.DECLINED) {
      displayDeclineResult();
    } else if (transactionStatus === TON_TRANSACTION_STATUS.SUCCESS) {
      dismissSentMsg();
      // displayTxDoneMsg();
    }
  }, [transactionStatus]);

  // handle position numbers
  function handlePositionChange(evt) {
    const value = evt.target.value;
    switch (true) {
      case value < MIN_BET_MEGA_DICE:
        setPostion(MIN_BET_MEGA_DICE);
        break;
      case value > MAX_BET_MEGA_DICE:
        setPostion(MAX_BET_MEGA_DICE);
        break;
      default:
        setPostion(value);
        sliderAudio.volume(0.65);
        playSound(sliderAudio);
    }
  }

  function handleIncreaseBet() {
    if (position !== MAX_BET_MEGA_DICE) {
      setPostion(Number((position + 0.01).toFixed(2)));
      playSound(sliderAudio);
    }
  }

  function handleReduceBet() {
    if (position !== MIN_BET_MEGA_DICE) {
      setPostion(Number((position - 0.01).toFixed(2)));
      playSound(sliderAudio);
    }
  }

  // toggle position range
  function toggleRange() {
    setUp((prevValue) => !prevValue);
    playSound(buttonsAudio);
  }

  // game results tracking function
  function checkGameQueue(result) {
    if (tonConnectUI) {
      // dismissMadeMsg();
      // dismissSentMsg();
      setGameQueue((prevArray) => [
        ...prevArray,
        {
          items: result,
          is_auto: result.length > 1,
        },
      ]);
    }
  }

  useEffect(() => {
    for (let i = gameQueueInitOffset; i < gameQueueInit.length; i++) {
      setGameQueueInitOffset(i + 1);
      checkGameQueue(gameQueueInit[i].result);
    }
  }, [gameQueueInit]);

  // display game result for single game
  function displayGameResult(result) {
    // dismissSentMsg();
    const payout_real = result.payout_real_amount;
    const payout = result.payout_amount;
    const random = ('0000' + result.random_value.toString())
      .slice(-4)
      .split('')
      .map((el) => Number(el));
    const newSlot1 = NUMBERS_ARR.map((el) =>
      (el + random[0]).toString().slice(-1)
    );
    const newSlot2 = NUMBERS_ARR.map((el) =>
      (el + random[1]).toString().slice(-1)
    );
    const newSlot3 = NUMBERS_ARR.map((el) =>
      (el + random[2]).toString().slice(-1)
    );
    const newSlot4 = NUMBERS_ARR.map((el) =>
      (el + random[3]).toString().slice(-1)
    );

    setTimeout(() => {
      setSlot4((prevSlot) => ({
        ...prevSlot,
        arr: newSlot4,
        rolling: false,
        rollingEnd: true,
      }));
    }, 1500);
    setTimeout(() => {
      setSlot3((prevSlot) => ({
        ...prevSlot,
        arr: newSlot3,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2000);
    setTimeout(() => {
      setSlot2((prevSlot) => ({
        ...prevSlot,
        arr: newSlot2,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2500);
    setTimeout(() => {
      setBlocked(false);
      setSlot1((prevSlot) => ({
        ...prevSlot,
        arr: newSlot1,
        rolling: false,
        rollingEnd: true,
      }));

      if (payout_real === 0) {
        displayLostMsg(true);
        setLost(true);
        mainApi.sendNotification({
          game_name: 'Megadice TON',
          bet_amount: `${betValue} on ${up ? '>' : '<'} ${position}`,
          result: `0 LOSE on ${('0000' + result.random_value.toString()).slice(-4)}`,
          wallet: wallet?.account?.address,
        })
      } else {
        displayWonMsg(payout + ` ${TON_CURRENCY}`, true);
        setWin(true);
        mainApi.sendNotification({
          game_name: 'Megadice TON',
          bet_amount: `${betValue} on ${up ? '>' : '<'} ${position}`,
          result: `${payout} WIN on ${('0000' + result.random_value.toString()).slice(-4)}`,
          wallet: wallet?.account?.address,
        })
      }

      setGamePlaying(false);
    }, 3000);
  }

  // display game result for autoplay
  function displayAUTOGameResult(result) {
    function timer(ms) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
        }, ms);
      });
    }

    async function showRes(res, index) {
      if (index !== 0) await timer(2200);

      // console.log({ res, index });
      // dismissSentMsg();
      const payout_real = res.payout_real_amount;
      const payout = res.payout_amount;
      const random = ('0000' + res.random_value.toString())
        .slice(-4)
        .split('')
        .map((el) => Number(el));
      const newSlot1 = NUMBERS_ARR.map((el) =>
        (el + random[0]).toString().slice(-1)
      );
      const newSlot2 = NUMBERS_ARR.map((el) =>
        (el + random[1]).toString().slice(-1)
      );
      const newSlot3 = NUMBERS_ARR.map((el) =>
        (el + random[2]).toString().slice(-1)
      );
      const newSlot4 = NUMBERS_ARR.map((el) =>
        (el + random[3]).toString().slice(-1)
      );

      setTimeout(() => {
        setSlot4((prevSlot) => ({
          ...prevSlot,
          arr: newSlot4,
          rolling: false,
          rollingEnd: true,
        }));
      }, 250);
      setTimeout(() => {
        setSlot3((prevSlot) => ({
          ...prevSlot,
          arr: newSlot3,
          rolling: false,
          rollingEnd: true,
        }));
      }, 500);
      setTimeout(() => {
        setSlot2((prevSlot) => ({
          ...prevSlot,
          arr: newSlot2,
          rolling: false,
          rollingEnd: true,
        }));
      }, 750);
      setTimeout(() => {
        setSlot1((prevSlot) => ({
          ...prevSlot,
          arr: newSlot1,
          rolling: false,
          rollingEnd: true,
        }));

        if (payout_real === 0) {
          displayLostMsg(index + 1 !== result.length);
          setLost(true);
        } else {
          displayWonMsg(
            payout + ` ${TON_CURRENCY}`,
            index + 1 !== result.length
          );
          setWin(true);
        }
      }, 1000);

      if (index + 1 !== result.length) {
        setTimeout(() => {
          setWin(false);
          setLost(false);
          setSlot1((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
          setSlot2((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
          setSlot3((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
          setSlot4((prevSlot) => ({
            ...prevSlot,
            rolling: true,
            rollingEnd: false,
          }));
        }, 2000);
      } else {
        setGamePlaying(false);
        setBlocked(false);
      }
    }

    result.reduce(async (a, res, index) => {
      // Wait for the previous item to finish processing
      await a;
      // Process this item
      await showRes(res, index);
    }, Promise.resolve());
  }

  // set transaction declined results
  function displayDeclineResult() {
    // dismissMadeMsg();
    // dismissSentMsg();
    // displayDeclinedMsg();

    setTimeout(() => {
      setSlot4((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
    }, 1500);
    setTimeout(() => {
      setSlot3((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2000);
    setTimeout(() => {
      setSlot2((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
    }, 2500);
    setTimeout(() => {
      setSlot1((prevSlot) => ({
        ...prevSlot,
        arr: NUMBERS_ARR,
        rolling: false,
        rollingEnd: true,
      }));
      setBlocked(false);
      setGamePlaying(false);
    }, 3000);
  }

  // request to smart contract
  async function sendGameTransaction() {
    const body = beginCell()
      .storeUint(0x475aaed3, 32)
      .storeUint(queryID ?? 0, 64)
      .storeUint(autoplayAmount, 8)
      .storeRef(
        beginCell()
          .storeUint(up, 1)
          .storeUint(Math.floor(position * 100), 16)
          .endCell()
      )
      .endCell();

    const messages = [
      {
        address: MEGA_DICE_ADDRESS,
        amount: toNano((betValue * autoplayAmount) + 0.15),
        payload: body.toBoc().toString('base64'),
      },
    ];

    const result = await sendTransaction(messages);
    if (result) {
      const parsedResult = parseGameResultMegaDice(result, tokenDecimals);
      setGameQueueInit((prev) => {
        return [...prev, { result: parsedResult }];
      });
    }
  }

  // start game
  function roll() {
    if (connected && !isDemo) {
      setGamePlaying(true);
      // displayMadeMsg();
      setBlocked(true);
      setWin(false);
      setLost(false);

      setSlot1((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
      setSlot2((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
      setSlot3((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
      setSlot4((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));

      sendGameTransaction();
    } else {
      const autoplayArray = Array(autoplayAmount)
        .fill()
        .map((_, i) => i);
      function timer(ms) {
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            resolve();
          }, ms);
        });
      }

      async function showRes(index) {
        if (index !== 0) await timer(3200);

        setWin(false);
        setLost(false);
        setSlot1((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));
        setSlot2((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));
        setSlot3((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));
        setSlot4((prevSlot) => ({
          ...prevSlot,
          rolling: true,
          rollingEnd: false,
        }));

        setTimeout(() => {

          const randomInt = Math.random() * 10000;
          const random = randomInt.toString().slice(-4);
          const randomValues = random.split('').map((el) => Number(el));
          const chance = !up ? position * 100 : 10000 - position * 100;
          const payoutX =
            (((1 * Math.pow(10, DEMO_DECIMALS)) / chance) *
              10000 *
              (10000 - gameFee)) /
            10000;
          const payout = up
            ? Number(random) / 100 > Number(position)
              ? (betValue * payoutX) / Math.pow(10, DEMO_DECIMALS)
              : 0
            : Number(random) / 100 < Number(position)
              ? (betValue * payoutX) / Math.pow(10, DEMO_DECIMALS)
              : 0;

          const newSlot1 = NUMBERS_ARR.map((el) =>
            (el + randomValues[0]).toString().slice(-1)
          );
          const newSlot2 = NUMBERS_ARR.map((el) =>
            (el + randomValues[1]).toString().slice(-1)
          );
          const newSlot3 = NUMBERS_ARR.map((el) =>
            (el + randomValues[2]).toString().slice(-1)
          );
          const newSlot4 = NUMBERS_ARR.map((el) =>
            (el + randomValues[3]).toString().slice(-1)
          );

          dismissSentMsg();
          setTimeout(() => {
            setSlot4((prevSlot) => ({
              ...prevSlot,
              arr: newSlot4,
              rolling: false,
              rollingEnd: true,
            }));
          }, 250);
          setTimeout(() => {
            setSlot3((prevSlot) => ({
              ...prevSlot,
              arr: newSlot3,
              rolling: false,
              rollingEnd: true,
            }));
          }, 500);
          setTimeout(() => {
            setSlot2((prevSlot) => ({
              ...prevSlot,
              arr: newSlot2,
              rolling: false,
              rollingEnd: true,
            }));
          }, 750);
          setTimeout(() => {
            setSlot1((prevSlot) => ({
              ...prevSlot,
              arr: newSlot1,
              rolling: false,
              rollingEnd: true,
            }));

            if (payout === 0) {
              setLost(true);
              if (index + 1 !== autoplayArray.length) {
                setFast(true);
                displayLostMsg(true);
                setTimeout(() => setFast(false), 700);
              } else {
                setFast(false);
                displayLostMsg(true);
              }
            } else {
              setWin(true);
              if (index + 1 !== autoplayArray.length) {
                displayWonMsg(
                  prettyValue(payout, tokenDecimals) + ` ${DEMO_CURRENCY}`,
                  true
                );
                setFast(true);
                setTimeout(() => setFast(false), 700);
              } else {
                displayWonMsg(
                  prettyValue(payout, tokenDecimals) + ` ${DEMO_CURRENCY}`,
                  true
                );
                setFast(false);
              }
              setDemoBalance((prevValue) => prevValue + payout);
            }

            if (index + 1 === autoplayArray.length) {
              setBlocked(false);
            }
          }, 1000);
        }, 1500);
      }
      setDemoBalance(
        (prevValue) => prevValue - betValue * autoplayArray.length
      );
      setBlocked(true);
      displaySentMsg();
      autoplayArray.reduce(async (a, _, index) => {
        await a;
        // Process this item
        await showRes(index);
        // Wait for the previous item to finish processing
      }, Promise.resolve());
    }
  }

  function checkAndStartAnim() {
    if (!slot1.rolling)
      setSlot1((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
    if (!slot2.rolling)
      setSlot2((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
    if (!slot3.rolling)
      setSlot3((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
    if (!slot4.rolling)
      setSlot4((prevSlot) => ({
        ...prevSlot,
        rolling: true,
        rollingEnd: false,
      }));
  }

  useEffect(() => {
    // console.log({ gameQueue, isGamePlaying });
    if (gameQueue.length > 0) {
      if (isGamePlaying) {
        if (gameQueue.length === 1) {
          // dismissSentMsg();
          setBlocked(true);
          checkAndStartAnim();
          if (gameQueue[0].is_auto) {
            setGamePlaying(true);
            displayAUTOGameResult(gameQueue[0].items);
            setGameQueue((prevArr) =>
              prevArr.filter(
                (item) =>
                  item.items[0].order_seqno !==
                  gameQueue[0].items[0].order_seqno
              )
            );
          } else {
            setGamePlaying(true);
            displayGameResult(gameQueue[0].items[0]);
            setGameQueue((prevArr) =>
              prevArr.filter(
                (item) =>
                  item.items[0].order_seqno !==
                  gameQueue[0].items[0].order_seqno
              )
            );
          }
        }
      }
    }
  }, [gameQueue, isGamePlaying]);

  function handlePlayResultsInQueue() {
    setBlocked(true);
    let itemsArray = [];
    gameQueue.forEach((element) => {
      element.items.forEach((item) => {
        itemsArray = [...itemsArray, item];
      });
    });
    const hashesArray = gameQueue.map((item) => {
      return item.items[0].order_seqno;
    });
    setGameQueue((prevArr) =>
      prevArr.filter(
        (item) => hashesArray.indexOf(item.items[0].order_seqno) < 0
      )
    );
    setGamePlaying(true);
    setWin(false);
    setLost(false);
    // dismissMadeMsg();
    // dismissSentMsg();
    setSlot1((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setSlot2((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setSlot3((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setSlot4((prevSlot) => ({ ...prevSlot, rolling: true, rollingEnd: false }));
    setTimeout(() => {
      displayAUTOGameResult(itemsArray);
    }, 2000);
  }

  return (
    <section
      className={`dice ${!isGamePlaying && gameQueue.length > 0 ? 'dice_with-tx-queue' : ''
        }`}
    >
      <div className="dice__game-block">
        <div
          className={`dice__result-block ${!isGamePlaying && gameQueue.length > 0
            ? 'dice__result-block_with-tx-queue'
            : ''
            }`}
        >
          {!isGamePlaying && gameQueue.length > 0 && (
            <button
              className="dice__tx-in-queue-btn dice__tx-in-queue-btn_mobile"
              type="button"
              onClick={handlePlayResultsInQueue}
            >
              See {gameQueue.length}{' '}
              {gameQueue.length > 1 ? `results` : 'result'}
            </button>
          )}
          <DiceResult
            {...{ slot1, slot2, slot3, slot4, isWin, isLost, isFast }}
          />
        </div>

        <div
          className={`dice__bet-block ${isBlocked ? 'dice__bet-block_disabled' : ''
            }`}
        >
          <DiceBet
            {...{
              up,
              position,
              handleReduceBet,
              handlePositionChange,
              handleIncreaseBet,
              toggleRange,
            }}
          />
        </div>

        <div className="dice__options-block">
          {
            connected && !minBet ? (
              <div className="dice__options-block-preloader">
                <MiniPreloader />
              </div>
            ) : (
              <GameOptions
                btnText="Roll"
                onPlayBtnClick={roll}
                rolling={spin}
                bet={betValue}
                setBet={setBetValue}
                queue={gameQueue}
                minMaxBet={{ min: minBet, max: maxBet }}
                autoplayText="Number of Games"

                {...{
                  chance,
                  payout,
                  isBlocked,
                  autoplayAmount,
                  setAutoplayAmount,
                  demoBalance,
                  isMaxBet,
                  isMinBet,
                  isDemo,
                  balance,
                  connected,
                  openTonConnectModal,
                }}
              />
            )
          }

          <div className="options__play-btn-box">
            <button
              className="options__play-btn"
              type="button"
              onClick={openTonConnectModal}
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MegaDice;
